.region-display {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px;
    border: 1px solid #6c757d;
    background-color: #343a40;
    text-transform: uppercase;
    font-size: 12px;
    z-index: 999;
}

.region-display:hover {
    background-color: #6c757d;
    cursor: pointer;
}